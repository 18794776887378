code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body {
    height: 100%;
    margin: 0;
    font-family: sans-serif;
}

#root {
    height: 100%;
    display:flow;
    background-color: #333333;
}
    #root > div {
        flex: 1; /* Allow divs to grow and shrink equally */
        max-height: 100%; /* Ensure divs do not exceed the height of #root */
        overflow: auto; /* Allow scrolling if content overflows */
    }
    
.form-group {
    padding-top: 1rem;
}

    .form-group label {
        text-align: left;
        padding-bottom: 0.5rem;
    }



.form-title {
    font-size: 1.6rem;
}

.form-control {
    border: 1px solid #D8D8D8 !important;
    border-radius: 4px !important;
    padding: 0.75rem; /* Increased padding for better appearance */
    width: 100%;
    font-size: 0.9rem;
    background-color: #F6F6F6 !important; /* Light gray background color */
    outline: none;
    transition: border-color 0.2s ease;
    line-height: 2 !important;
}

    .form-control:focus {
        border-color: #007bff; /* Border color on focus */
    }

    .form-control::placeholder {
        color: #A6A6A6 !important; /* Change this to your desired placeholder color */
        font-weight: 500;
    }

.form-check-input {
    margin-right: 5px !important;
    border: 0.15rem solid #999999 !important;
    width: 1.3rem !important;
    height: 1.3rem !important;
    margin-right: 0.9rem !important;
    border-radius: 0.2rem !important;
}

.form-check-label {
    color: #A6A6A6;
    font-weight: 500;
    padding-top: 0.2rem;
}

.btn-primary {
    width: 100%;
    background-color: #0088FF !important; /* Primary button color */
    border-color: #007bff;
    height: 2.7rem;
    font-size: 0.9rem;
    border-radius: 0.2rem !important ;
}

    .btn-primary:hover {
        background-color: #0056b3;
        border-color: #0056b3;
    }


.password-group {
    position: relative;
    color: #A6A6A6;
    font-weight: 500;
}


.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

@media (max-width: 992px) {

    .form-title {
        font-size: 5rem !important;
    }

    .form-control {
        margin-top:1rem !important;
        padding-left:2.5rem !important;
        font-size: 2.5rem !important;
        line-height: 3 !important;
    }

    .form-group {
        margin-top: 5rem !important;
    }

    .form-check-input {
        border: 0.3rem solid #999999 !important;
        width: 3rem !important;
        height: 3rem !important;
        margin-right: 0.9rem !important;
        border-radius: 0.2rem !important;
    }

    .form-check-label {
        padding-top: 0rem !important;
    }

    .btn-primary {
        width: 100%;
        background-color: #0088FF !important; /* Primary button color */
        border-color: #007bff;
        height: 8rem;
        font-size: 2.5rem !important;
        border-radius: 0.5rem !important;
        margin-top: 5rem;
    }
    .password-toggle-icon {
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .error-message {
        font-size: 2.5rem !important;
        margin-top: 3rem;
    }
}