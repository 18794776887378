.sidebar {
    min-width: 4.8rem;
    width: 4.8rem;
    height: 100%;
    min-height: 100vh;
    background-color: #333333;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.3rem;
    font-family: 'Arial Rounded MT', 'Segoe UI', sans-serif;
    transition: width 0.1s;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    top: 0; /* Place the sidebar at the top of the viewport */
    left: 0; /* Place the sidebar on the left side of the viewport */
    z-index: 1000; /* Set a high z-index to ensure the sidebar appears on top */
}
    .sidebar > div::-webkit-scrollbar {
        display: none;
    }
    .sidebar.expanded {
        width: 17rem;
        align-items: flex-start;
        position: fixed;
    }

    .sidebar.expanded .sidebar-header {
        white-space: nowrap;
    }

    .sidebar.expanded .logout-button{
        margin-left:0.5rem;
        width:90%
    }
    .sidebar.expanded hr.rounded {
        border-radius: 0.1rem;
        border-top: 0.2rem solid #FF000A;
        margin-left: 1.2rem;
        opacity: 1;
        margin-bottom: 1rem;
    }
    .sidebar.expanded .sidebar-logo {
        margin-left: 0.6rem;
    }

    .sidebar.expanded .sidebar-li-icon-container {
        margin-right: 0.6rem;
    }
    .sidebar.expanded .sidebar-ul ul {
        padding-left: 1rem !important;
    }

    .sidebar.expanded .sidebar-li-icon {
        margin-right: 1rem;
    }
        .sidebar.expanded .sidebar-logo-label {
            font-size: 1rem;
            display: inline-block;
            white-space: nowrap;
            transform: translateX(-10%); /* Start position off screen to the left */
            animation: slide-in 0.5s forwards;
        }
        .sidebar.expanded .icon-label, .sidebar.expanded .button-label {
            display: inline-block;
            vertical-align: middle;
            font-size: 0.9rem;
            transform: translateX(-100%); /* Start position off screen to the left */
            animation: slide-in 0.5s forwards;
        }
        .sidebar.expanded .logout-position {
           width:50%
        }
@keyframes slide-in {
    to {
        transform: translateX(0); /* End position at original place */
    }
}
.sidebar-logo-label {
    font-size: 0.01rem;
    display: none;
} 

.sidebar-logo {
    width: 2.5rem;
    cursor: pointer;
}

hr.rounded {
    border-radius: 0.1rem;
    border-top: 0.2rem solid #FF000A;
    opacity: 1;
    margin-bottom: 1rem;
}

.sidebar-ul ul {
    list-style: none;
    padding-left: 0 !important;
    width: 100%;
}

.sidebar-ul a {
    text-decoration: none;
    color: white;
}

.sidebar-ul li.active,
.sidebar-ul li:hover {
    background-color: #FF000A;
    border-radius: 0.5rem;
}

.sidebar-li {
    width: 90%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.logout-button {
    background-color: #333333;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.icon-label, .button-label {
    font-size: 0.01rem;
    display: none;
}

.sidebar-li-icon-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 2.9rem !important;
}

.left-icon {
    margin-left: 0.5rem;
    margin-right: 1.3rem;
    position: relative;
    z-index: 2;
}

.right-icon {
    position: absolute;
    left: 1.3rem;
    z-index: 1;
}

.logout-position {
    position: absolute;
    bottom: 1rem;
}

@media (max-width: 992px) {

    .sidebar{
        display:none;
    }

        .sidebar.expanded {
            display: inline-block;
            margin-top: 12.5rem;
            width: 100%;
            padding: 2.5rem;
            padding-right:4rem;
        }

    .sidebar-logo-label {
        font-size: 3.5rem !important;
        transform: none !important;
        animation: none !important;
    }

    .sidebar-logo {
        width: 7rem;
    }
    hr.mobile-hr {
        border-radius: 0.1rem;
        border-top: 0.5rem solid #FF000A !important;
        margin-bottom: 6rem !important;
        margin-top: 3rem !important;
        opacity:1;
        margin-left:2rem;
    }
    .sidebar-header{
        display:flex;
        align-items:center;
        margin-top:2.5rem;
    }
    .icon-label, .button-label {
        font-size: 3rem !important;
        display: inline-block !important;
        margin-left: 7rem;
        transform: none !important;
        animation: none !important;
    }
    .button-label {
        margin-left: 3.5rem !important;
    }
    .sidebar-icon {
        font-size: 5rem !important;
        margin-left: 3.5rem;
    }
    .left-icon, .right-icon {
        font-size: 4rem !important;
    }
    .left-icon {
        margin-right:2rem!important;
        margin-left:3rem;
    }
    .right-icon {
        margin-left: 1rem;
    }
    .sidebar-li {
        margin-bottom:2rem;
        margin-left:2rem;
        width:96%;
    }
    .sidebar-ul li.active,
    .sidebar-ul li:hover {
        border-radius: 1.3rem;
    }
    .logout-position {
        position: absolute;
        bottom: 15rem;
    }
    .sidebar-li-icon{
        font-size: 5rem !important;
        margin-left: 2rem;
    }
}