.email-sent-icon {
    font-size: 8rem !important;
    color: #3ED47A;
    margin-top: 2rem;
}
.email-sent-annoucement {
    margin-top:3rem;
    margin-left: 4rem;
    margin-right: 4rem;
    font-size: 0.85rem;
}

@media (max-width: 992px) {
    .email-sent-icon {
        margin-top: 8rem;
        font-size: 24rem !important;
    }

    .email-sent-annoucement {
        margin-top: 10rem;
        margin-left: 4rem;
        margin-right: 4rem;
        font-size: 2.5rem;
    }
}