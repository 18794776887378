.auth-main-container {
    background-color: #333333;
    flex: content;
    flex-direction: column;
    justify-content: center; /* Center the white card horizontally */
    align-items: center; /* Center the white card vertically */
    padding-top: 4.5rem;
    padding-bottom:5rem;
}

.auth-logo-container{
    margin-bottom:2.5rem;
}

.auth-card {
    background-color: white;
    padding: 1rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow to the card */
    width: 31.5rem;
    height: auto;
    text-align: center;
    font-family: 'Arial Rounded MT','Segoe UI', sans-serif;
    max-height: 100%;
    margin-left:0.6rem;
}

.logo-label {
    font-size: 1.68rem;
    font-family: 'Arial Rounded MT', 'Segoe UI', sans-serif;
    color: white;
}

.auth-logo {
    width: 4rem;
}

.auth-form .form-group {
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-family: 'Segoe UI', sans-serif;
    font-weight: 600;
}
    .auth-form label {
        font-size: 0.9rem;
    }


@media (max-width: 992px) {
    .auth-card {
        width: 90%;
        height: 75vh; /* Adjust the width to fit the mobile screen */
        padding: 1.5rem; /* Adjust the padding for better spacing */
        margin-left: 0; /* Remove the left margin for centering */
        border-radius: 1rem;
        font-family: 'Arial Rounded MT','Segoe UI', sans-serif;
        padding-top:7rem;
    }

    .auth-main-container {
        padding-top: 6rem;
        padding-bottom: 2rem;
    }

    .auth-form .form-group {
        padding-left: 1rem;
        padding-right: 1rem;
        font-family: 'Segoe UI', sans-serif;
        font-weight: 600;
    }
    .auth-form {
        padding-top: 7rem;
    }
    .auth-form label {
        font-size: 2.5rem;
    }
    

    .auth-logo {
        width: 7rem; /* Adjust the logo size for mobile */
    }

    .logo-label {
        font-size: 3rem; /* Adjust the label size for mobile */
    }
}