.form-title {
    font-size: 1.6rem;
}
.auth-form {
    margin-top: 0.8rem;
}

.login-link {
    color: #0088FF;
    font-size: 0.9rem;
    text-decoration: none;
    padding-bottom: 1rem;
}

    .login-link:hover {
        text-decoration: underline;
    }

.back-to-login-span {
    color: #A6A6A6;
    font-size: 0.9rem;
}

@media (max-width: 992px) {
    .back-to-login-span {
        font-size: 2.5rem;
    }
    .login-link {
        font-size: 2.5rem;
    }
    .auth-form {
        padding-top:0rem;
    }
    .forgot-password-btn-container{
        margin-top:15rem;
    }
}