
.forgot-password-link {
    color: #9B9FA5;
    font-size: 0.9rem;
    text-decoration: none;
    padding-bottom: 1rem;
}

    .forgot-password-link:hover {
        text-decoration: underline;
    }
.material-symbols-rounded {
    font-size: 1.5rem !important;
}
.auth-form {
    margin-top: 3rem !important;
}

.remember-password {
    margin-top: 1rem;
    margin-bottom: 0 !important;
}

@media (max-width: 992px) {
    .forgot-password-link {
        font-size: 2.5rem;
        text-decoration: none;
        padding-bottom: 1rem;
    }
    .material-symbols-rounded {
        font-size: 4.5rem !important;
    }
    .remember-password {
        margin-top: 2.5rem !important;
        margin-bottom: 0 !important;
    }

    .login-form {
        margin-top: 7rem !important;
    }
    .form-group{
        padding-top:3rem !important;
    }
}