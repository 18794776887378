
.base {
    flex-grow: 1;
    display: flex;
    height: 100%;
    min-height: 100vh;
    font-family: sans-serif;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

    /* .base > div::-webkit-scrollbar {
        display: none;
    } */
    .base > div { 
        max-height: 100%; 
        overflow: auto; 
    }
.main-content {
    flex-grow: 1;
    min-width: 0; 
    min-height: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: sans-serif;
    background-color: #F5F5F5;
}

.content {
    padding: 1rem;
    height: 100%;
    margin-top: 4rem;
}

@media (max-width: 992px) {
    .content {
        margin-top: 13rem;
    }
}