.topbar {
    background-color: #fff;
    padding: 0.4rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Arial Rounded MT', 'Segoe UI', sans-serif;
    position: fixed;
    top: 0;
    right: 0;
    left: 4.8rem;
    z-index: 888;
}
.menu-btn {
    display:none;
}
.user-info {
    display: flex;
    align-items: center;
}

    .user-info span {
        margin-right: 10px;
    }

.user-role {
    color: #888;
}

.icon-notification {
    font-size: 1.5rem;
    color: #A6A6A6;
    margin-right:1.5rem;
}
.topbar-title {
    font-size: 1.2rem;
    color: #FF000A;
}

.user-info-container {
    display: flex;
    align-items: center;
    padding: 0.7rem;
    font-family: 'Segoe UI', sans-serif;
}


.profile-pic {
    width: 2.5rem;
    height:2.5rem;
    border-radius: 50%;
    margin-right: 10px;
    border:0.1rem solid lightgray;
}

.user-details {
    display: flex;
    flex-direction: column;
}

.user-name {
    font-size: 0.85rem;
    font-weight: 500;
}

.user-role {
    font-size: 0.85rem;
    color: red;
    font-weight:500;
}

@media (max-width: 992px) {

    .topbar-title {
        opacity: 0;
    }

    .icon-notification {
        font-size: 4rem !important;
        margin-right: 3rem;
    }
    .user-name {
        display:none;
    }

    .user-role {
        display: none;
    }
    .profile-pic {
        width: 6.2rem;
        height: 6.2rem;
    }
    .topbar {
        padding: 2.5rem 4rem;
        left:0;
    }

    .icon-menu {
        font-size: 6rem;
        color: #A6A6A6;
        margin-left:1rem;
    }
    .menu-btn{
        display:inline-block;
        background-color:transparent;
        border:none;
    }

}