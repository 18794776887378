@media (max-width: 992px) {
    @media (max-width: 992px) {

        .btn-primary {
            margin-top: 6rem !important;
        }
        .reset-success-btn{
            padding-top: 2rem !important;
        }

        .form-group {
            margin-top: 0 !important;
        }
    }
}
